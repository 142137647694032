import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSelectedCategory,
  setSelectedPersonalCategory,
} from "../store/slices/uiSlice";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import RecordView from "./RecordView";

const personalCategories = [{ id: "General_Note", label: "General Note" }];

const CategoryButton = ({ category, selected, onClick, className = "" }) => (
  <button
    onClick={onClick}
    className={`flex justify-center items-center px-4 py-3 text-sm font-medium rounded-md w-full ${selected
        ? "bg-primary-100 text-primary-700 ring-2 ring-primary-500"
        : "bg-white text-gray-700 ring-1 ring-gray-300 hover:bg-gray-50"
      } ${className}`}
  >
    {category.label}
  </button>
);

const RecordPersonalNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategoryLocal] = useState(null);

  const handleNext = () => {
    if (step === 1 && selectedCategory) {
      setStep(2);
      dispatch(setSelectedCategory("personal"));
      dispatch(setSelectedPersonalCategory(selectedCategory));
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate("/record-page");
    }
  };

  return (
    <div className="max-w-full mx-auto">
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-sm text-gray-500">Step {step} of 2</span>
          </div>
          <div className="w-2/3 bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-primary-600 h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${(step / 2) * 100}%` }}
            ></div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6 mt-6">
          {step === 1 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Select Category
              </h2>
              <div className="flex justify-center w-full px-4">
                {personalCategories.map((category) => (
                  <CategoryButton
                    key={category.id}
                    category={category}
                    selected={selectedCategory === category.id}
                    onClick={() => setSelectedCategoryLocal(category.id)}
                    className="w-full max-w-md"
                  />
                ))}
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Record Personal Note
              </h2>
              <RecordView />
            </div>
          )}

          {/* Navigation buttons */}
          <div className="mt-8 flex justify-between">
            <button
              onClick={handleBack}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <ChevronLeftIcon className="w-5 h-5 mr-2" />
              Back
            </button>
            {step < 2 && (
              <button
                onClick={handleNext}
                disabled={!selectedCategory}
                className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-md ${!selectedCategory
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-primary-600 hover:bg-primary-700"
                  }`}
              >
                Next
                <ChevronRightIcon className="w-5 h-5 ml-2" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordPersonalNote;
