import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { useMsal } from "@azure/msal-react";

const ProtectedRoute = ({ element: Component }) => {
  const { accounts, authToken } = useAuth();
  const { inProgress } = useMsal();

  if (inProgress !== "none") {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (!accounts.length || !authToken) {
    return <Navigate to="/" replace />;
  }

  return Component;
};

export default ProtectedRoute; 