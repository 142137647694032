import { PublicClientApplication } from "@azure/msal-browser";

// MSAL configuration object
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication request configuration
export const loginRequest = {
  scopes: ["User.Read"],
};

// Initialize MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

export default msalConfig; 