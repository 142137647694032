import { store } from '../store/store';
import { clearAudioState } from '../store/slices/audioSlice';
import { apiClient, API_ENDPOINTS } from '../api/apiConfig';

export class AudioService {
  constructor() {
    this.websocket = null;
    this.audioContext = null;
    this.audioSource = null;
    this.audioProcessor = null;
    this.stream = null;
    this.conversationId = null;
    this.logAudioMetrics = true;

    // Default settings that will be adjusted based on device capabilities
    this.audioSettings = {
      bufferSize: 2048,
      sampleRate: 44100,
      gainValue: 1.0,
      latencyHint: 'interactive',
      amplitudeThreshold: 0.95,
      limitingRatio: 4,
    };
  }

  async detectOptimalSettings() {
    const ua = navigator.userAgent.toLowerCase();

    // Detect browser type
    const browserInfo = {
      isSamsung: ua.includes('samsungbrowser'),
      isChrome: ua.includes('chrome'),
      isSafari: ua.includes('safari') && !ua.includes('chrome'),
      isFirefox: ua.includes('firefox'),
      isMobile: /mobile|android|iphone|ipad|ipod/i.test(ua)
    };

    // Adjust settings based on browser and device type
    if (browserInfo.isMobile) {
      this.audioSettings.bufferSize = 4096;
      this.audioSettings.latencyHint = 'balanced';
      this.audioSettings.gainValue = 0.6;
      this.audioSettings.amplitudeThreshold = 0.7;
      this.audioSettings.limitingRatio = 10;
    }

    if (browserInfo.isSamsung) {
      this.audioSettings.sampleRate = 44100;
      this.audioSettings.bufferSize = 8192;
      this.audioSettings.gainValue = 0.5;
      this.audioSettings.amplitudeThreshold = 0.6;
      this.audioSettings.limitingRatio = 12;
    }

    if (browserInfo.isSafari) {
      this.audioSettings.bufferSize = 4096;
      this.audioSettings.latencyHint = 'playback';
    }

    // Log detected settings
    console.log('[Audio] Browser Detection:', browserInfo);
    console.log('[Audio] Optimal Settings:', this.audioSettings);
  }

  async setConversationId(conversationId) {
    this.conversationId = conversationId;
  }

  async startRecording(websocketUrl) {
    try {
      await this.detectOptimalSettings();
      console.log(`[Audio] Browser: ${navigator.userAgent}`);

      // Get device capabilities first
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      console.log('[Audio] Available audio devices:', audioDevices);

      // Try to get supported constraints
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
      console.log('[Audio] Supported Constraints:', supportedConstraints);

      // Build audio constraints based on supported features
      const audioConstraints = {
        audio: {
          channelCount: 1,
          echoCancellation: supportedConstraints.echoCancellation || true,
          noiseSuppression: supportedConstraints.noiseSuppression || true,
          autoGainControl: supportedConstraints.autoGainControl || true,
          latencyHint: this.audioSettings.latencyHint
        }
      };

      this.websocket = new WebSocket(websocketUrl);
      await new Promise((resolve, reject) => {
        this.websocket.onopen = () => resolve();
        this.websocket.onerror = (error) => reject(error);
      });

      // Create audio context with optimal settings
      this.audioContext = new window.AudioContext({
        latencyHint: this.audioSettings.latencyHint,
        sampleRate: this.audioSettings.sampleRate
      });

      this.stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      const audioTrack = this.stream.getAudioTracks()[0];
      const settings = audioTrack.getSettings();

      // Adjust settings based on actual capabilities
      this.audioSettings.sampleRate = Math.min(
        this.audioSettings.sampleRate,
        settings.sampleRate || this.audioSettings.sampleRate
      );

      console.log('[Audio] Track Settings:', settings);
      console.log('[Audio] Final Sample Rate:', this.audioSettings.sampleRate);

      this.audioSource = this.audioContext.createMediaStreamSource(this.stream);
      this.audioProcessor = this.audioContext.createScriptProcessor(
        this.audioSettings.bufferSize,
        1,
        1
      );

      let frameCount = 0;
      let lastLog = Date.now();
      let maxAmplitude = 0;

      this.audioProcessor.onaudioprocess = (e) => {
        if (this.websocket?.readyState === WebSocket.OPEN) {
          const float32Array = e.inputBuffer.getChannelData(0);
          const int16Array = new Int16Array(float32Array.length);

          // More sophisticated audio processing
          for (let i = 0; i < float32Array.length; i++) {
            let sample = float32Array[i];
            const threshold = this.audioSettings.amplitudeThreshold;
            const ratio = this.audioSettings.limitingRatio;

            // Soft knee compression
            if (Math.abs(sample) > threshold) {
              const excess = Math.abs(sample) - threshold;
              const compression = excess / ratio;
              sample = (sample > 0 ? 1 : -1) * (threshold + compression);
            }

            // Additional smoothing for Samsung browser
            if (this.audioSettings.sampleRate === 44100) {
              // Simple 2-point averaging to reduce high-frequency noise
              if (i > 0) {
                sample = (sample + float32Array[i - 1]) * 0.5;
              }
            }

            int16Array[i] = Math.max(-32768, Math.min(32767, Math.round(sample * 32767)));
          }

          if (this.logAudioMetrics) {
            const currentMax = Math.max(...float32Array.map(Math.abs));
            maxAmplitude = Math.max(maxAmplitude, currentMax);
            frameCount++;

            if (Date.now() - lastLog >= 1000) {
              console.log('[Audio] Metrics:', {
                framesProcessed: frameCount,
                maxAmplitude: maxAmplitude.toFixed(4),
                bufferSize: float32Array.length,
                sampleRate: e.inputBuffer.sampleRate,
              });
              frameCount = 0;
              maxAmplitude = 0;
              lastLog = Date.now();
            }
          }

          this.websocket.send(int16Array.buffer);
        }
      };

      // Create and configure gain node with optimal gain
      const gainNode = this.audioContext.createGain();
      gainNode.gain.value = this.audioSettings.gainValue;

      this.audioSource.connect(gainNode);
      gainNode.connect(this.audioProcessor);
      this.audioProcessor.connect(this.audioContext.destination);

    } catch (error) {
      console.error("Error starting recording:", error);
      throw error;
    }
  }

  async endConversation(conversationId) {
    await apiClient.post(`${API_ENDPOINTS.END_CONVERSATION}/${conversationId}`);
  }

  async stopRecording() {
    try {
      if (this.audioProcessor) {
        this.audioProcessor.disconnect();
        this.audioSource.disconnect();
        this.audioProcessor = null;
        this.audioSource = null;
      }

      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }

      if (this.websocket) {
        this.websocket.close();
        this.websocket = null;
      }
      if (this.conversationId) {
        await this.endConversation(this.conversationId);
        this.conversationId = null;
      }

      store.dispatch(clearAudioState());
    } catch (error) {
      console.error("Error stopping recording:", error);
      throw error;
    }
  }

  getStream() {
    return this.stream;
  }

}

export const audioService = new AudioService();