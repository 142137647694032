import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./msalConfig";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      if (accounts.length > 0 && inProgress === "none") {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
          });
          setAuthToken(response.accessToken);
        } catch (error) {
          // If silent token acquisition fails, you might want to acquire token using popup or redirect
          console.error("Silent token acquisition failed", error);
          if (error.name === "InteractionRequiredAuthError") {
            instance.acquireTokenRedirect(loginRequest);
          }
        }
      }
    };

    getToken();
  }, [instance, accounts, inProgress]);

  const contextValue = {
    authToken,
    accounts,
    instance
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 