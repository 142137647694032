import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./auth/msalConfig";
import { store } from "./store/store";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Login from "./components/Login";
import RecordView from "./components/RecordView";
import NotesView from "./components/NotesView";
import RecordViewPage from "./components/RecordViewPage";
import RecordChildNote from "./components/RecordChildNote";
import RecordHomeNote from "./components/RecordHomeNote";
import RecordPersonalNote from "./components/RecordPersonalNote";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./auth/AuthContext";
import { initHotjar } from "./utils/hotjar";

function App() {
  useEffect(() => {
    initHotjar();
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/home"
                element={
                  <>
                    <Layout>
                    <ProtectedRoute element={<Home />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/notes"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<NotesView />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record-page"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordViewPage />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record/child"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordChildNote />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record/home"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordHomeNote />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record/personal"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordPersonalNote />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordView />} />
                    </Layout>
                  </>
                }
              />
            </Routes>
          </Router>
        </Provider>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
