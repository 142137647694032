import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";

export const startRecording = createAsyncThunk(
  "audio/startRecording",
  async (_, { getState }) => {
    const state = getState();
    const {
      selectedCategory,
      selectedChildCategory,
      selectedChild,
      selectedHomeCategory,
      selectedPersonalCategory,
      selectedHome,
      additionalNoteText,
    } = state.ui;

    let requestData = {
      category:
        selectedCategory === "personal"
          ? selectedPersonalCategory
          : selectedCategory === "child"
            ? selectedChildCategory
            : selectedHomeCategory,
      child_id: selectedCategory === "child" ? selectedChild.id : undefined,
      home_id: selectedCategory === "home" ? selectedHome.id : undefined,
      additional_notes: additionalNoteText,
    };

    const response = await apiClient.post(
      API_ENDPOINTS.START_CONVERSATION,
      requestData
    );
    return response;
  }
);

const initialState = {
  websocketUrl: null,
  isConnecting: false,
  error: null,
  currentConversationId: null,
};

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    setWebsocketUrl: (state, action) => {
      state.websocketUrl = action.payload;
    },
    setCurrentConversationId: (state, action) => {
      state.currentConversationId = action.payload;
    },
    clearAudioState: (state) => {
      state.websocketUrl = null;
      state.currentConversationId = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startRecording.pending, (state) => {
        state.isConnecting = true;
        state.error = null;
      })
      .addCase(startRecording.fulfilled, (state, action) => {
        state.isConnecting = false;
        state.websocketUrl = action.payload.websocket_url;
        state.currentConversationId = action.payload.conversation_id;
      })
      .addCase(startRecording.rejected, (state, action) => {
        state.isConnecting = false;
        state.error = action.error.message;
      });
  },
});

export const { setWebsocketUrl, setCurrentConversationId, clearAudioState } =
  audioSlice.actions;
export default audioSlice.reducer;
