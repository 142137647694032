import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCategory,
  setSelectedHomeCategory,
  setSelectedHomeState,
} from "../store/slices/uiSlice";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import RecordView from "./RecordView";
import { apiClient, API_ENDPOINTS } from "../api/apiConfig";

const homeCategories = [{ id: "General_Note", label: "General Note" }];

const CategoryButton = ({ category, selected, onClick, className = "" }) => (
  <button
    onClick={onClick}
    className={`flex justify-center items-center px-4 py-3 text-sm font-medium rounded-md w-full ${selected
      ? "bg-primary-100 text-primary-700 ring-2 ring-primary-500"
      : "bg-white text-gray-700 ring-1 ring-gray-300 hover:bg-gray-50"
      } ${className}`}
  >
    {category.label}
  </button>
);

const HomeButton = ({ home, selected, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center px-4 py-3 text-sm font-medium rounded-md ${selected
      ? "bg-primary-100 text-primary-700 ring-2 ring-primary-500"
      : "bg-white text-gray-700 ring-1 ring-gray-300 hover:bg-gray-50"
      }`}
  >
    <span className="flex-1 text-left">{home.name}</span>
  </button>
);

const CreateHomeModal = ({ isOpen, onClose, onSubmit }) => {
  const [homeName, setHomeName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!homeName.trim()) {
      setError("Home Name is required");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const queryParams = new URLSearchParams({
        home_name: homeName,
      }).toString();

      const response = await apiClient.post(
        `${API_ENDPOINTS.CREATE_HOME}?${queryParams}`
      );

      onSubmit(response);
      setHomeName("");
      onClose();
    } catch (error) {
      setError(error.message || "Failed to create home");
      console.error("Failed to create home:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-medium text-gray-900">Add New Home</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-2 bg-red-50 border border-red-200 rounded text-red-600 text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="homeName"
              className="block text-sm font-medium text-gray-700"
            >
              Home Name
            </label>
            <input
              type="text"
              id="homeName"
              value={homeName}
              onChange={(e) => setHomeName(e.target.value)}
              className="mt-1 h-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder="Enter home's name"
              required
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md disabled:bg-gray-300"
            >
              {isSubmitting ? "Creating..." : "Create Home"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const RecordHomeNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [selectedHome, setSelectedHome] = useState(null);
  const [selectedCategory, setSelectedCategoryLocal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [homes, setHomes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHomes();
  }, []);

  const fetchHomes = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get(API_ENDPOINTS.GET_HOMES);
      setHomes(response.homes || []);
    } catch (err) {
      setError("Failed to load homes");
      console.error("Error fetching homes:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateHome = async (newHome) => {
    await fetchHomes(); // Refresh the homes list after creating a new home
  };

  const handleNext = () => {
    if (step === 1 && selectedHome) {
      setStep(2);
    } else if (step === 2 && selectedCategory) {
      setStep(3);
      dispatch(setSelectedCategory("home"));
      dispatch(setSelectedHomeCategory(selectedCategory));
      dispatch(setSelectedHomeState(selectedHome));
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate("/record-page");
    }
  };

  return (
    <div className="max-w-full mx-auto">
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-sm text-gray-500">Step {step} of 3</span>
          </div>
          <div className="w-2/3 bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-primary-600 h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${(step / 3) * 100}%` }}
            ></div>
          </div>
        </div>

        <div className="mt-4 flex items-center justify-center space-x-2 text-sm">
          {selectedHome && (
            <span className="px-2 py-1 bg-primary-50 text-primary-700 rounded-md">
              Home: {selectedHome.name}
            </span>
          )}
          {selectedCategory && (
            <>
              <span className="text-gray-400">•</span>
              <span className="px-2 py-1 bg-primary-50 text-primary-700 rounded-md">
                Category:{" "}
                {homeCategories.find((c) => c.id === selectedCategory)?.label ||
                  selectedCategory}
              </span>
            </>
          )}
        </div>

        <div className="bg-white shadow rounded-lg p-6 mt-6">
          {step === 1 && (
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold text-gray-900">
                  Select Home
                </h2>
                <button
                  onClick={() => setShowCreateModal(true)}
                  disabled={isLoading}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md disabled:bg-gray-300"
                >
                  {isLoading ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                      Loading...
                    </>
                  ) : (
                    <>
                      <PlusIcon className="h-5 w-5 mr-2" />
                      Add Home
                    </>
                  )}
                </button>
              </div>

              {isLoading ? (
                <div className="text-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600 mx-auto"></div>
                  <p className="mt-2 text-sm text-gray-500">Loading homes...</p>
                </div>
              ) : error ? (
                <div className="text-center py-4">
                  <p className="text-red-600">{error}</p>
                  <button
                    onClick={fetchHomes}
                    className="mt-2 text-primary-600 hover:text-primary-700"
                  >
                    Try again
                  </button>
                </div>
              ) : homes.length === 0 ? (
                <div className="text-center py-4">
                  <p className="text-gray-500">
                    No homes found. Add a home to get started.
                  </p>
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-3">
                  {homes.map((home) => (
                    <HomeButton
                      key={home.home_id}
                      home={{
                        id: home.home_id,
                        name: home.home_name,
                      }}
                      selected={selectedHome?.id === home.home_id}
                      onClick={() =>
                        setSelectedHome({
                          id: home.home_id,
                          name: home.home_name,
                        })
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Select Category
              </h2>
              <div className="flex justify-center w-full px-4">
                {homeCategories.map((category) => (
                  <CategoryButton
                    key={category.id}
                    category={category}
                    selected={selectedCategory === category.id}
                    onClick={() => setSelectedCategoryLocal(category.id)}
                    className="w-full max-w-md"
                  />
                ))}
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Record Home Note
              </h2>
              <RecordView />
            </div>
          )}

          {/* Navigation buttons */}
          <div className="mt-8 flex justify-between">
            <button
              onClick={handleBack}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <ChevronLeftIcon className="w-5 h-5 mr-2" />
              Back
            </button>
            {step < 3 && (
              <button
                onClick={handleNext}
                disabled={
                  (step === 1 && !selectedHome) ||
                  (step === 2 && !selectedCategory)
                }
                className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-md ${(step === 1 && !selectedHome) ||
                  (step === 2 && !selectedCategory)
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-primary-600 hover:bg-primary-700"
                  }`}
              >
                Next
                <ChevronRightIcon className="w-5 h-5 ml-2" />
              </button>
            )}
          </div>
        </div>
      </div>

      <CreateHomeModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={handleCreateHome}
      />
    </div>
  );
};

export default RecordHomeNote;
