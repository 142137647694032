import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes } from "../store/slices/notesSlice";
import { fetchAudioUrl } from "../api/apiConfig";
import {
  toggleSelectedNote,
  clearSelectedNotes,
} from "../store/slices/uiSlice";
import {
  DocumentTextIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
  ClipboardDocumentIcon,
  UserIcon,
  TrashIcon,
  PencilIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { Tab, RadioGroup } from "@headlessui/react";
import { apiClient } from "../api/apiConfig";
import { API_ENDPOINTS } from "../api/apiConfig";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AudioPlayer = ({ recordId }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setIsLoading(true);
      fetchAudioUrl(recordId)
        .then(setAudioUrl)
        .finally(() => setIsLoading(false));
    }
  }, [recordId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-4">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary-600"></div>
        <span className="ml-2 text-sm text-gray-500">Loading audio...</span>
      </div>
    );
  }

  return (
    <audio
      src={audioUrl}
      controls
      className="w-full"
      controlsList="nodownload"
      onError={(e) => {
        e.target.outerHTML = '<p class="text-sm text-gray-500 italic">Audio not available</p>';
      }}
    />
  );
};

function NotesView() {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.notes);
  const loading = useSelector((state) => state.notes.loading);
  const selectedNotes = useSelector((state) => state.ui.selectedNotes);
  const [filter, setFilter] = useState("child");
  const [customPrompt, setCustomPrompt] = useState("");
  const [showSummarizeModal, setShowSummarizeModal] = useState(false);
  const [expandedNoteId, setExpandedNoteId] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [summarizedNotes, setSummarizedNotes] = useState([]);
  const [copiedTranscript, setCopiedTranscript] = useState(false);
  const [copiedAdditionalNotes, setCopiedAdditionalNotes] = useState(false);
  const [copiedSummary, setCopiedSummary] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [expandedSummaryId, setExpandedSummaryId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [editingPrompt, setEditingPrompt] = useState(null);
  const [editedPromptText, setEditedPromptText] = useState("");
  const [editingSummary, setEditingSummary] = useState(null);
  const [editedSummaryText, setEditedSummaryText] = useState("");
  const [copiedGeneratedSummary, setCopiedGeneratedSummary] = useState(false);
  const [predefinedPrompts, setPredefinedPrompts] = useState([]);
  const [selectedPredefinedPrompts, setSelectedPredefinedPrompts] = useState([]);
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [selectedRecordDetails, setSelectedRecordDetails] = useState(null);
  const [loadingRecordDetails, setLoadingRecordDetails] = useState(false);
  const [showDatePickers, setShowDatePickers] = useState(false);

  useEffect(() => {
    dispatch(fetchNotes());
  }, [dispatch]);

  const uniqueCategories = [...new Set(notes?.records?.map(note => note.category) || [])];

  const filteredNotes =
    notes?.records?.filter((note) => {
      const matchesType = filter === "all" || note.record_type === `${filter}_note`;
      const matchesCategory = categoryFilter === "all" || note.category === categoryFilter;
      return matchesType && matchesCategory;
    }) || [];

  const handleDateFilterChange = (range) => {
    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0)); // Start of today

    // Set showDatePickers based on selection
    setShowDatePickers(range !== "");

    switch (range) {
      case "":  // All Time
        setStartDate(null);
        setEndDate(null);
        break;
      case "today":
        setStartDate(today);
        setEndDate(new Date(now.setHours(23, 59, 59, 999))); // End of today
        break;
      case "yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setStartDate(yesterday);
        setEndDate(new Date(yesterday.setHours(23, 59, 59, 999)));
        break;
      case "last_week":
        setStartDate(new Date(now.setDate(now.getDate() - 7)));
        setEndDate(new Date());
        break;
      case "last_month":
        setStartDate(new Date(now.setMonth(now.getMonth() - 1)));
        setEndDate(new Date());
        break;
      case "custom":
        if (!startDate && !endDate) {
          setStartDate(null);
          setEndDate(null);
        }
        break;
      default:
        setStartDate(null);
        setEndDate(null);
    }
  };

  const filteredNotesByDate = filteredNotes.filter((note) => {
    const noteDate = new Date(note.updated_date);
    if (startDate && endDate) {
      return noteDate >= startDate && noteDate <= endDate;
    }
    return true;
  });

  const fetchSummarizedNotes = async () => {
    setLoadingSummaries(true);
    try {
      const response = await apiClient.get(
        `${API_ENDPOINTS.GET_CUSTOM_SUMMARIES}`
      );
      if (response.custom_summaries) {
        setSummarizedNotes(response.custom_summaries);
      }
    } catch (error) {
      console.error("Failed to fetch summarized notes:", error);
    } finally {
      setLoadingSummaries(false);
    }
  };

  useEffect(() => {
    fetchSummarizedNotes();
  }, []);

  const handleSummarize = async () => {
    if (selectedNotes.length === 0) return;

    setIsSummarizing(true);
    try {
      const queryParams = new URLSearchParams({
        custom_prompt: selectedPredefinedPrompts.length > 0
          ? selectedPredefinedPrompts.map(p => p.systemPrompt).join(' | ')
          : customPrompt || "undefined",
      }).toString();

      const response = await apiClient.post(
        `${API_ENDPOINTS.SUMMARIZE_NOTES}?${queryParams}`,
        selectedNotes.map(String)
      );
      if (response) {
        await fetchSummarizedNotes();
        dispatch(clearSelectedNotes());
        setShowSummarizeModal(false);
        setCustomPrompt("");
        setSelectedPredefinedPrompts([]);
        setActiveTab(1);
      }
    } catch (error) {
      console.error("Failed to summarize notes:", error);
    } finally {
      setIsSummarizing(false);
    }
  };

  const handleCopy = async (text, setCopied) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      // Reset after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const handleDeleteNote = async (recordId) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      try {
        await apiClient.delete(API_ENDPOINTS.DELETE_RECORD(recordId));
        // Refresh the notes list
        dispatch(fetchNotes());
        // Refresh summarized notes
        fetchSummarizedNotes();
      } catch (error) {
        console.error('Failed to delete note:', error);
      }
    }
  };

  const handleUpdatePrompt = async (summaryId) => {
    try {
      await apiClient.put(API_ENDPOINTS.UPDATE_CUSTOM_SUMMARY(summaryId), {
        custom_prompt: editedPromptText
      });

      // Refresh summaries after update
      await fetchSummarizedNotes();
      setEditingPrompt(null);
      setEditedPromptText("");
    } catch (error) {
      console.error("Failed to update prompt:", error);
    }
  };

  const handleUpdateSummary = async (recordId) => {
    try {
      await apiClient.put(API_ENDPOINTS.UPDATE_RECORD(recordId), {
        summary: editedSummaryText
      });

      // Refresh notes after update
      dispatch(fetchNotes());
      setEditingSummary(null);
      setEditedSummaryText("");
    } catch (error) {
      console.error("Failed to update summary:", error);
    }
  };

  const fetchPredefinedPrompts = async () => {
    setLoadingPrompts(true);
    try {
      const selectedRecords = notes?.records?.filter(note => selectedNotes.includes(note.record_id)) || [];
      const recordType = [...new Set(selectedRecords.map(note => note.record_type))];
      const category = [...new Set(selectedRecords.map(note => note.category))];

      const queryParams = new URLSearchParams({
        record_type: recordType.join(','),
        category: category.join(',')
      }).toString();

      const response = await apiClient.get(`${API_ENDPOINTS.GET_CUSTOM_PROMPTS}?${queryParams}`);

      const formattedPrompts = response?.prompts?.map(prompt => ({
        id: prompt.name,
        title: prompt.name.split('_').map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' '),
        systemPrompt: prompt.prompt.content || ''
      }));

      setPredefinedPrompts(formattedPrompts);
    } catch (error) {
      console.error('Failed to fetch predefined prompts:', error);
    } finally {
      setLoadingPrompts(false);
    }
  };

  useEffect(() => {
    if (showSummarizeModal) {
      fetchPredefinedPrompts();
    }
  }, [showSummarizeModal]);

  const fetchRecordDetails = async (recordId) => {
    setLoadingRecordDetails(true);
    try {
      // Find the record from the existing notes data
      const record = notes?.records?.find(note => note.record_id === recordId);
      if (record) {
        setSelectedRecordDetails(record);
      }
    } catch (error) {
      console.error('Failed to fetch record details:', error);
    } finally {
      setLoadingRecordDetails(false);
    }
  };

  // Update the filtered summaries logic to include note type filtering
  const filteredSummaries = summarizedNotes
    .filter((summary) => {
      // Date filter
      const summaryDate = new Date(summary.created_date);
      const meetsDateFilter = startDate && endDate ?
        summaryDate >= startDate && summaryDate <= endDate :
        true;

      // Note type filter
      const meetsTypeFilter = filter === 'all' ?
        true :
        summary.records.some(record => {
          const note = notes?.records?.find(n => n.record_id === record.record_id);
          return note?.record_type === `${filter}_note`;
        });

      // Category filter
      const meetsCategoryFilter = categoryFilter === 'all' ?
        true :
        summary.records.some(record => {
          const note = notes?.records?.find(n => n.record_id === record.record_id);
          return note?.category === categoryFilter;
        });

      return meetsDateFilter && meetsTypeFilter && meetsCategoryFilter;
    })
    // Sort in reverse chronological order
    .sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

  return (
    <div className="space-y-6">
      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <Tab.List className="flex space-x-1 rounded-lg bg-blue-900/20 p-1 mb-2">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Notes
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Summaries
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">Your Notes</h2>
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full sm:w-auto">
                {showDatePickers && (
                  <div className="flex items-center gap-4">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        handleDateFilterChange('custom');
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      className="w-full rounded-md border-gray-300 py-2 pl-3 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      dateFormat="MMM d, yyyy"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        handleDateFilterChange('custom');
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      className="w-full rounded-md border-gray-300 py-2 pl-3 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      dateFormat="MMM d, yyyy"
                    />
                  </div>
                )}
                <select
                  onChange={(e) => handleDateFilterChange(e.target.value)}
                  className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                >
                  <option value="">All Time</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last_week">Last Week</option>
                  <option value="last_month">Last Month</option>
                  <option value="custom">Custom Range</option>
                </select>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
                >
                  <option value="child">Child Notes</option>
                  <option value="personal">Personal Notes</option>
                  <option value="home">Home Notes</option>
                </select>

                <select
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
                >
                  <option value="all">All Categories</option>
                  {uniqueCategories.map(category => (
                    <option key={category} value={category}>
                      {category?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    </option>
                  ))}
                </select>

                {selectedNotes.length > 0 && (
                  <button
                    onClick={() => setShowSummarizeModal(true)}
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md w-full sm:w-auto"
                  >
                    <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
                    Summarize Selected ({selectedNotes.length})
                  </button>
                )}
              </div>
            </div>

            <div>
              <div className="bg-white shadow overflow-hidden sm:rounded-md">
                {/* Loading State */}
                {loading && (
                  <div className="flex justify-center items-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                  </div>
                )}

                {/* No Notes State */}
                {!loading && (!filteredNotesByDate || filteredNotesByDate.length === 0) && (
                  <div className="text-center py-12">
                    <p className="text-gray-500">Notes not available</p>
                  </div>
                )}

                {/* Notes List */}
                {!loading && filteredNotesByDate.length > 0 && (
                  <ul className="divide-y-0">
                    {filteredNotesByDate.map((note) => (
                      <li
                        key={note.record_id}
                        className={`border-l-4 transition-colors duration-200 ${expandedNoteId === note.record_id
                          ? "border-l-primary-600 bg-gray-50"
                          : "border-l-transparent hover:bg-gray-50"
                          } ${selectedNotes.includes(note.record_id)
                            ? "bg-primary-50"
                            : ""
                          }`}
                      >
                        <div
                          className={`px-4 py-4 sm:px-6 cursor-pointer transition-all duration-200 ${expandedNoteId === note.record_id
                            ? "bg-white shadow-sm"
                            : ""
                            }`}
                          onClick={() =>
                            setExpandedNoteId(
                              expandedNoteId === note.record_id
                                ? null
                                : note.record_id
                            )
                          }
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                checked={selectedNotes.includes(note.record_id)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  setExpandedNoteId(false);
                                  dispatch(toggleSelectedNote(note.record_id));
                                }}
                                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                              />
                              <div className="ml-3">
                                <div className="flex items-center">
                                  <p className="text-sm font-medium text-gray-900">
                                    {note.record_type
                                      .split("_")[0]
                                      .charAt(0)
                                      .toUpperCase() +
                                      note.record_type
                                        .split("_")[0]
                                        .slice(1)}{" "}
                                    Note
                                  </p>
                                  {note.child_id && (
                                    <div className="ml-2 flex items-center text-sm text-gray-500">
                                      <UserIcon className="h-4 w-4 mr-1" />
                                      {note.child_id}
                                    </div>
                                  )}
                                </div>
                                {note.category && (
                                  <>
                                    <p className="text-sm text-gray-500">
                                      {note.category
                                        .replace(/_/g, " ")
                                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                                    </p>
                                    <p className="text-xs text-gray-400">
                                      Record ID: {note.record_id}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="flex space-x-4 items-center justify-between">
                                <div className="flex items-center space-x-4">
                                  <svg
                                    className={`h-5 w-5 transform transition-transform ${expandedNoteId === note.record_id
                                      ? "rotate-180"
                                      : ""
                                      }`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">
                                      {new Date(note.updated_date).toLocaleString()}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      Submitted By: {note.user_name}
                                    </span>
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteNote(note.record_id);
                                  }}
                                  className="p-1 hover:bg-red-50 rounded-full"
                                >
                                  <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-600" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {expandedNoteId !== note.record_id && (
                            <div className="mt-2">
                              <p className="text-sm text-gray-600 line-clamp-2">
                                {note.summary || "AI Summary not available"}
                              </p>
                            </div>
                          )}
                        </div>

                        {/* Expanded Detail View */}
                        {expandedNoteId === note.record_id && (
                          <div className="mx-4 mb-4 bg-gray-50">
                            <div className="px-6 py-5">
                              {note.childName && (
                                <div className="flex items-center mb-6 p-4 bg-white rounded-lg shadow-sm">
                                  <UserIcon className="h-5 w-5 text-gray-400 mr-2" />
                                  <div>
                                    <p className="text-sm font-medium text-gray-900">
                                      {note.childName}
                                    </p>
                                    {note.childCategory && (
                                      <p className="text-sm text-gray-500">
                                        {note.childCategory}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}

                              <div className="space-y-4">
                                {/* Audio Section */}
                                <div className="bg-white rounded-lg p-4 shadow-sm">
                                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                                    Audio Recording
                                  </h4>
                                  <AudioPlayer recordId={note.record_id} />
                                </div>
                                <div className="bg-white rounded-lg p-4 shadow-sm">
                                  <div className="flex justify-between items-center mb-3">
                                    <h4 className="text-sm font-medium text-gray-900">
                                      Typed Notes
                                    </h4>
                                    {note.additional_notes && (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCopy(
                                            note.additional_notes,
                                            setCopiedAdditionalNotes
                                          );
                                        }}
                                        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                      >
                                        <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                        {copiedAdditionalNotes ? "Copied" : "Copy"}
                                      </button>
                                    )}
                                  </div>
                                  <div className="bg-gray-50 rounded-md p-4">
                                    {note.additional_notes ? (
                                      <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                        {note.additional_notes}
                                      </p>
                                    ) : (
                                      <p className="text-sm text-gray-500 italic">
                                        Typed Notes not available
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {/* AI Summary Section - Moved up */}
                                <div className="bg-white rounded-lg p-4 shadow-sm">
                                  <div className="flex justify-between items-center mb-3">
                                    <h4 className="text-sm font-medium text-gray-900">
                                      AI Summary
                                    </h4>
                                    <div className="flex items-center space-x-2">
                                      {editingSummary === note.record_id ? (
                                        <>
                                          <button
                                            onClick={() => handleUpdateSummary(note.record_id)}
                                            className="inline-flex items-center text-sm text-green-600 hover:text-green-700 px-2 py-1 rounded-md hover:bg-green-50"
                                          >
                                            <CheckIcon className="h-4 w-4 mr-1" />
                                            Save
                                          </button>
                                          <button
                                            onClick={() => {
                                              setEditingSummary(null);
                                              setEditedSummaryText("");
                                            }}
                                            className="inline-flex items-center text-sm text-red-600 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50"
                                          >
                                            <XMarkIcon className="h-4 w-4 mr-1" />
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={() => {
                                              setEditingSummary(note.record_id);
                                              setEditedSummaryText(note.summary || "");
                                            }}
                                            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                          >
                                            <PencilIcon className="h-4 w-4 mr-1" />
                                            Edit
                                          </button>
                                          {note.summary && (
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleCopy(note.summary, setCopiedSummary);
                                              }}
                                              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                            >
                                              <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                              {copiedSummary ? "Copied" : "Copy"}
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="bg-primary-50 rounded-md p-4">
                                    {editingSummary === note.record_id ? (
                                      <textarea
                                        value={editedSummaryText}
                                        onChange={(e) => setEditedSummaryText(e.target.value)}
                                        className="w-full min-h-[100px] p-2 text-sm text-gray-600 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                                        placeholder="Enter summary..."
                                      />
                                    ) : note.summary ? (
                                      <pre className="text-sm text-gray-600 whitespace-pre-wrap font-sans">
                                        {note.summary}
                                      </pre>
                                    ) : (
                                      <p className="text-sm text-gray-500 italic">
                                        AI Summary not available
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {/* Transcript Section - Moved down */}
                                <div className="bg-white rounded-lg p-4 shadow-sm">
                                  <div className="flex justify-between items-center mb-3">
                                    <h4 className="text-sm font-medium text-gray-900">
                                      Transcript
                                    </h4>
                                    {note.transcript && (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCopy(note.transcript, setCopiedTranscript);
                                        }}
                                        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                      >
                                        <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                        {copiedTranscript ? "Copied" : "Copy"}
                                      </button>
                                    )}
                                  </div>
                                  <div className="bg-gray-50 rounded-md p-4">
                                    {note.transcript ? (
                                      <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                        {note.transcript}
                                      </p>
                                    ) : (
                                      <p className="text-sm text-gray-500 italic">
                                        Transcript not available
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">Your Summaries</h2>
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full sm:w-auto">
                {showDatePickers && (
                  <div className="flex items-center gap-4">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        handleDateFilterChange('custom');
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      className="w-full rounded-md border-gray-300 py-2 pl-3 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      dateFormat="MMM d, yyyy"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        handleDateFilterChange('custom');
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      className="w-full rounded-md border-gray-300 py-2 pl-3 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      dateFormat="MMM d, yyyy"
                    />
                  </div>
                )}
                <select
                  onChange={(e) => handleDateFilterChange(e.target.value)}
                  className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                >
                  <option value="">All Time</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last_week">Last Week</option>
                  <option value="last_month">Last Month</option>
                  <option value="custom">Custom Range</option>
                </select>

                {/* Add note type filter */}
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
                >
                  <option value="child">Child Notes</option>
                  <option value="personal">Personal Notes</option>
                  <option value="home">Home Notes</option>
                </select>

                <select
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                  className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
                >
                  <option value="all">All Categories</option>
                  {uniqueCategories.map(category => (
                    <option key={category} value={category}>
                      {category?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              {/* Loading State */}
              {loadingSummaries && (
                <div className="flex justify-center items-center py-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                </div>
              )}

              {/* No Summaries State */}
              {!loadingSummaries && (!filteredSummaries || filteredSummaries.length === 0) && (
                <div className="text-center py-12">
                  <p className="text-gray-500">No summaries found</p>
                </div>
              )}

              {/* Summaries List - Use filteredSummaries instead of summarizedNotes */}
              {!loadingSummaries && filteredSummaries.length > 0 && (
                <ul className="divide-y-0">
                  {filteredSummaries.map((summary) => (
                    <li
                      key={summary.custom_summary_id}
                      className={`border-l-4 transition-colors duration-200 ${expandedSummaryId === summary.custom_summary_id
                        ? "border-l-primary-600 bg-gray-50"
                        : "border-l-transparent hover:bg-gray-50"
                        }`}
                    >
                      <div className={`px-4 py-4 sm:px-6 cursor-pointer transition-all duration-200 ${expandedSummaryId === summary.custom_summary_id ? "bg-white shadow-sm" : ""
                        }`}
                        onClick={() => setExpandedSummaryId(
                          expandedSummaryId === summary.custom_summary_id ? null : summary.custom_summary_id
                        )}>
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-lg font-medium text-gray-900">
                              Summary of {summary.records.length} {
                                (() => {
                                  // Get unique note types and categories from the records
                                  const noteTypes = [...new Set(summary.records
                                    .map(record => {
                                      const note = notes?.records?.find(n => n.record_id === record.record_id);
                                      return note?.record_type?.split('_')[0];
                                    })
                                    .filter(Boolean)
                                  )];

                                  const categories = [...new Set(summary.records
                                    .map(record => {
                                      const note = notes?.records?.find(n => n.record_id === record.record_id);
                                      return note?.category;
                                    })
                                    .filter(Boolean)
                                  )];

                                  // Format note types
                                  const typeString = noteTypes
                                    .map(type => type.charAt(0).toUpperCase() + type.slice(1))
                                    .join('/');

                                  // Format categories
                                  const categoryString = categories
                                    .map(cat => cat.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()))
                                    .join(', ');

                                  return `${typeString} Notes${categoryString ? ` (${categoryString})` : ''}`;
                                })()
                              }
                            </h4>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <svg
                                className={`h-5 w-5 transform transition-transform ${expandedSummaryId === summary.custom_summary_id ? "rotate-180" : ""
                                  }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <div className="flex flex-col text-sm text-gray-500">
                                <span>
                                  {new Date(summary.created_date).toLocaleString()}
                                </span>
                                <span>
                                  Generated By: {summary.user_name}
                                </span>
                              </div>
                            </div>
                            {/* Commented out trash icon button
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteNote(summary.records[0].record_id);
                              }}
                              className="p-1 hover:bg-red-50 rounded-full"
                            >
                              <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-600" />
                            </button>
                            */}
                          </div>
                        </div>

                        {/* Preview of summary when not expanded */}
                        {expandedSummaryId !== summary.custom_summary_id && (
                          <div className="mt-2">
                            <p className="text-sm text-gray-600 line-clamp-2">
                              {summary.summary || "Summary not available"}
                            </p>
                          </div>
                        )}
                      </div>

                      {/* Expanded View */}
                      {expandedSummaryId === summary.custom_summary_id && (
                        <div className="mx-4 mb-4 bg-gray-50">
                          <div className="px-6 py-5 space-y-4">
                            <div className="bg-white rounded-lg p-4 shadow-sm">
                              <h4 className="text-sm font-medium text-gray-900 mb-3">
                                Included Notes
                              </h4>
                              <div className="space-y-2">
                                {loadingRecordDetails && (
                                  <div className="flex items-center justify-center py-4 bg-gray-50 rounded-md">
                                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary-600"></div>
                                    <span className="ml-2 text-sm text-gray-500">Loading record details...</span>
                                  </div>
                                )}
                                {notes?.records
                                  ?.filter(note => summary.records.some(r => r.record_id === note.record_id))
                                  ?.map(note => (
                                    <div
                                      key={note.record_id}
                                      className={`bg-gray-50 rounded-md p-4 cursor-pointer hover:bg-gray-100 transition-colors ${loadingRecordDetails ? 'opacity-50 pointer-events-none' : ''
                                        }`}
                                      onClick={() => fetchRecordDetails(note.record_id)}
                                    >
                                      <div className="flex justify-between items-start">
                                        <div>
                                          <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">
                                              {note.record_type.split("_")[0].charAt(0).toUpperCase() +
                                                note.record_type.split("_")[0].slice(1)} Note
                                            </p>
                                            <span className="ml-2 text-sm text-gray-500">
                                              (Record ID: {note.record_id})
                                            </span>
                                          </div>
                                          <p className="text-sm text-gray-500">
                                            {note.category?.replace(/_/g, " ")
                                              .replace(/\b\w/g, l => l.toUpperCase())}
                                          </p>
                                        </div>
                                        <span className="text-sm text-gray-500">
                                          {new Date(note.updated_date).toLocaleString()}
                                        </span>
                                      </div>
                                      <div className="mt-1 text-xs text-primary-600">
                                        Click to view full details
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>

                            {/* Generated Summary Section */}
                            <div className="bg-white rounded-lg p-4 shadow-sm">
                              <div className="flex justify-between items-center mb-3">
                                <h4 className="text-sm font-medium text-gray-900">
                                  Generated Summary
                                </h4>
                                {summary.summary && (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopy(summary.summary, setCopiedGeneratedSummary);
                                    }}
                                    className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                  >
                                    <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                    {copiedGeneratedSummary ? "Copied" : "Copy"}
                                  </button>
                                )}
                              </div>
                              <div className="bg-primary-50 rounded-md p-4">
                                <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                  {summary.summary || "Summary not available"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* Summarize Modal */}
      {showSummarizeModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Summarize {selectedNotes.length} Notes
              </h3>
              <button
                onClick={() => setShowSummarizeModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Selected Notes
                </label>
                <div className="mt-2 max-h-40 overflow-y-auto">
                  {notes?.records
                    ?.filter((note) => selectedNotes.includes(note.record_id))
                    ?.map((note) => (
                      <div
                        key={note.record_id}
                        className="flex items-center py-2"
                      >
                        <DocumentTextIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <span className="text-sm text-gray-600">
                          {note.category
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (l) => l.toUpperCase())}{" "}
                          -{new Date(note.updated_date).toLocaleString()}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-4">
                  Custom Instructions
                </label>
                <div className="space-y-4">
                  <div className="space-y-2">
                    {loadingPrompts ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
                      </div>
                    ) : predefinedPrompts.length > 0 ? (
                      <RadioGroup
                        value={selectedPredefinedPrompts[0] || null}
                        onChange={(value) => setSelectedPredefinedPrompts(value ? [value] : [])}
                      >
                        <div className="space-y-2">
                          {predefinedPrompts.map((prompt) => (
                            <RadioGroup.Option
                              key={prompt.id}
                              value={prompt}
                              className={({ checked }) => `
                                relative flex cursor-pointer rounded-lg border p-4 focus:outline-none
                                ${checked
                                  ? 'bg-primary-50 border-primary-500 text-primary-700'
                                  : 'bg-white border-gray-300 hover:bg-gray-50'
                                }
                              `}
                            >
                              {({ checked }) => (
                                <div className="flex w-full items-center justify-between">
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label
                                        className={`font-medium ${checked ? 'text-primary-700' : 'text-gray-900'
                                          }`}
                                      >
                                        {prompt.title}
                                      </RadioGroup.Label>
                                    </div>
                                  </div>
                                  <div className={`shrink-0 ${checked ? 'text-primary-600' : 'text-gray-400'
                                    }`}>
                                    <div className={`
                                      h-4 w-4 rounded-full border flex items-center justify-center
                                      ${checked
                                        ? 'border-primary-600 bg-primary-600'
                                        : 'border-gray-300'
                                      }
                                    `}>
                                      <div className={`
                                        h-2 w-2 rounded-full bg-white
                                        ${checked ? 'opacity-100' : 'opacity-0'}
                                      `} />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    ) : (
                      <div className="text-sm text-gray-500 italic p-4 bg-gray-50 rounded-lg">
                        No predefined instructions available for these notes
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <p className="text-sm text-gray-500 mb-2">
                      {predefinedPrompts.length > 0 ? 'Or write' : 'Write'} your own custom instructions:
                    </p>
                    <textarea
                      value={customPrompt}
                      onChange={(e) => {
                        setCustomPrompt(e.target.value);
                        setSelectedPredefinedPrompts([]);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      rows={4}
                      placeholder="E.g., Summarize the child's behavior patterns across these observations..."
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowSummarizeModal(false)}
                  disabled={isSummarizing}
                  className={`px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md ${isSummarizing ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSummarize}
                  disabled={isSummarizing}
                  className={`px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md inline-flex items-center justify-center min-w-[140px] ${isSummarizing
                    ? "opacity-75 cursor-not-allowed"
                    : "hover:bg-primary-700"
                    }`}
                >
                  {isSummarizing ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Generating...
                    </>
                  ) : (
                    <>
                      <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
                      Generate Summary
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add this new Record Details Modal */}
      {selectedRecordDetails && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Record Details <span className="text-gray-500">({selectedRecordDetails.record_id})</span>
              </h3>
              <button
                onClick={() => setSelectedRecordDetails(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {loadingRecordDetails ? (
              <div className="flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
              </div>
            ) : (
              <div className="space-y-4">
                {/* Record Type and Category */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500">Record Type</p>
                      <p className="text-sm text-gray-900">
                        {selectedRecordDetails.record_type?.split("_")[0].charAt(0).toUpperCase() +
                          selectedRecordDetails.record_type?.split("_")[0].slice(1)} Note
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">Category</p>
                      <p className="text-sm text-gray-900">
                        {selectedRecordDetails.category?.replace(/_/g, " ")
                          .replace(/\b\w/g, l => l.toUpperCase())}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Audio Player */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">Audio Recording</h4>
                  <AudioPlayer recordId={selectedRecordDetails.record_id} />
                </div>

                {/* AI Summary */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">AI Summary</h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.summary || "Summary not available"}
                  </p>
                </div>

                {/* Transcript */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">Transcript</h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.transcript || "Transcript not available"}
                  </p>
                </div>

                {/* Additional Notes */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">Additional Notes</h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.additional_notes || "No additional notes"}
                  </p>
                </div>

                {/* Metadata */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">Updated Date</p>
                    <p className="text-sm text-gray-900">
                      {new Date(selectedRecordDetails.updated_date).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NotesView;
