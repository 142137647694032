import { msalInstance } from '../auth/msalConfig';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
  SUMMARIZE_NOTES: `${API_BASE_URL}/notes/summarise`,
  GET_RECORDS: `${API_BASE_URL}/records`,
  START_CONVERSATION: `${API_BASE_URL}/notes/record`,
  END_CONVERSATION: `${API_BASE_URL}/notes/end`,
  GET_CUSTOM_SUMMARIES: `${API_BASE_URL}/custom_summaries`,
  CREATE_CHILD: `${API_BASE_URL}/children/create`,
  GET_CHILDREN: `${API_BASE_URL}/children`,
  CREATE_HOME: `${API_BASE_URL}/homes/create`,
  GET_HOMES: `${API_BASE_URL}/homes`,
  DELETE_RECORD: (recordId) => `${API_BASE_URL}/record/${recordId}`,
  UPDATE_RECORD: (recordId) => `${API_BASE_URL}/record/${recordId}`,
  UPDATE_CUSTOM_SUMMARY: (summaryId) => `${API_BASE_URL}/custom_summary/${summaryId}`,
  GET_RECORDING: (recordId) => `${API_BASE_URL}/recordings/${recordId}`,
  GET_CUSTOM_PROMPTS: `${API_BASE_URL}/custom_prompts`,
};

export const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Something went wrong");
  }
  return response.json();
};

export const apiClient = {
  get: async (url) => {
    const account = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: account
    });

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${tokenResponse.accessToken}`
      }
    });
    return handleResponse(response);
  },

  post: async (url, data) => {
    const account = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: account
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${tokenResponse.accessToken}`
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },

  put: async (url, data) => {
    const account = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: account
    });

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${tokenResponse.accessToken}`
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },

  delete: async (url) => {
    const account = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: account
    });

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${tokenResponse.accessToken}`
      }
    });
    return handleResponse(response);
  },
};

export const fetchAudioUrl = async (recordId) => {
  try {
    const account = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: account
    });
    const response = await fetch(API_ENDPOINTS.GET_RECORDING(recordId),
      {
        headers: {
          'Authorization': `Bearer ${tokenResponse.accessToken}`
        }
      })
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    return url
  } catch (error) {
    console.error("Failed to fetch audio URL:", error);
  }
};