import { configureStore } from '@reduxjs/toolkit';
import notesReducer from './slices/notesSlice';
import uiReducer from './slices/uiSlice';
import audioReducer from './slices/audioSlice';

export const store = configureStore({
  reducer: {
    notes: notesReducer,
    ui: uiReducer,
    audio: audioReducer,
  },
}); 