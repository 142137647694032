import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";

export const fetchNotes = createAsyncThunk("notes/fetchNotes", async () => {
  const response = await apiClient.get(API_ENDPOINTS.GET_RECORDS);
  return response;
});

export const summarizeNotes = createAsyncThunk(
  "notes/summarizeNotes",
  async (data) => {
    const response = await apiClient.post(API_ENDPOINTS.SUMMARIZE_NOTES, data);
    return response;
  }
);

export const fetchSummarizedNotes = createAsyncThunk(
  "notes/fetchSummarizedNotes",
  async () => {
    const response = await apiClient.get(`${API_ENDPOINTS.GET_RECORDS}/summaries`);
    return response;
  }
);

const initialState = {
  notes: [],
  summarizedNotes: [],
  loading: false,
  error: null,
  currentRecording: null,
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setCurrentRecording: (state, action) => {
      state.currentRecording = action.payload;
    },
    clearCurrentRecording: (state) => {
      state.currentRecording = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch notes";
      })
      .addCase(fetchSummarizedNotes.fulfilled, (state, action) => {
        state.summarizedNotes = action.payload.records;
      })
      .addCase(summarizeNotes.fulfilled, (state, action) => {
        if (action.payload.summary) {
          state.summarizedNotes.unshift(action.payload);
        }
      });
  },
});

export const { setCurrentRecording, clearCurrentRecording } =
  notesSlice.actions;
export default notesSlice.reducer;
